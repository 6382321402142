import * as Sentry from '@sentry/nextjs';
import { NextPageContext } from 'next';
import NextErrorComponent from 'next/error';
import Head from 'components/next/Head';
import Typography from '../components/generics/texts/Typography';
import classes from './index.module.scss';
import React from 'react';
import Image from 'next/image';

const CustomErrorComponent = () => {
  return (
    <div className={classes.errorContainer}>
      <Head title={'Not Found'} />
      <Typography className={classes.title}>Page Not Found</Typography>
      <Image
        alt={'Not found'}
        src={'/assets/illustrations/not_found.jpg'}
        width={400}
        height={400}
      />
    </div>
  );
};

CustomErrorComponent.getInitialProps = async (contextData: NextPageContext) => {
  await Sentry.captureUnderscoreErrorException(contextData);
  return NextErrorComponent.getInitialProps(contextData);
};
export default CustomErrorComponent;
